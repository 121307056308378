<template>

  <DefaultCrudView
    @create-new="newSecurityCategory"
    :breadcrumbsItems="breadcrumbs"
    :actions="crudActions"
    containerLarge
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="security_categories"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.tenant_id="{ item }">
          {{ getTenantTitle(item.tenant_id) }}
        </template>

        <template v-slot:item.actions_key="{ item }">
          <span
            v-for="(key, index) in item.actions_key"
            :key="key"
            color="primary"
            dark
          >
            {{ key }}<span v-if="index != item.actions_key.length - 1">, </span> 
          </span>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editSecurityCategory(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="modalDeleteSecurityCategory(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('delete_security_category')"
        ref="deleteModal"
        @submit="removeSecurityCategory"
        submit-button-text="delete"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('remove_security_category') }} '<b>{{ selectedSecurityCategory ? selectedSecurityCategory.mark_category_key : '' }}</b>' ?
        </template>
      </DefaultModal>

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import ActionActions from '@/store/operation/action/actions-types'
import SecurityCategoryActions from '@/store/operation/security_category/actions-types'
import SecurityCategoryMutations from '@/store/operation/security_category/mutations-types'
import AppActions from '@/store/app/actions-types'
import TenantActions from '@/store/core/tenant/actions-types'

export default {
  name: "SecurityCategory",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapGetters('app', [
      'getIconForOperationSettingsItem'
    ]),

    ...mapState({
      fields: state => state.operation.security_category.fields,
      activesets: state => state.operation.security_category.security_categories,
      headers: state => state.operation.security_category.tableHeaders,
      selectedSecurityCategory: state => state.operation.security_category.selectedSecurityCategory
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('security_categories'),
          prependIcon: this.getIconForOperationSettingsItem('security'),
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      security_categories: [],
      options: {
        sortBy: ['mark_category_key'],
        sortDesc: [false],
      },
      itemsLength: 0,
      crudActions: [
        {
          text: "create_security_category",
          eventToEmit: "create-new",
        }
      ],
      tenants: [],
      actions: []
    }
  },

  mounted() {
    this.[SecurityCategoryMutations.SET_SELECTED_SECURITYCATEGORY](null)

    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[SecurityCategoryMutations.SET_FIELD_TENANTS](tenants.items)
        this.tenants = tenants.items
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('operation/action', [
      ActionActions.GET_ALL_ACTIONS
    ]),

    ...mapActions('operation/security_category', [
      SecurityCategoryActions.GET_ALL_SECURITYCATEGORIES,
      SecurityCategoryActions.SAVE_SECURITYCATEGORY,
      SecurityCategoryActions.UPDATE_SECURITYCATEGORY,
      SecurityCategoryActions.DELETE_SECURITYCATEGORY
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapMutations('operation/security_category', [
      SecurityCategoryMutations.SET_FIELD_SECURITYCATEGORIES,
      SecurityCategoryMutations.SET_SELECTED_SECURITYCATEGORY,
      SecurityCategoryMutations.SET_FIELD_TENANTS
    ]),

    newSecurityCategory() {
      this.[SecurityCategoryMutations.SET_SELECTED_SECURITYCATEGORY](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this.[SecurityCategoryActions.SAVE_SECURITYCATEGORY]()
        .then(() => {
          let message = this.selectedSecurityCategory ? 'SecurityCategory edited' : 'SecurityCategory created'
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
          this.$refs.modal.closeModal()
          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)
      this.loading = true
      this[SecurityCategoryActions.GET_ALL_SECURITYCATEGORIES](options)
        .then((result) => {
          this.security_categories = result.items
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    editSecurityCategory(security_category) {
      this[SecurityCategoryMutations.SET_SELECTED_SECURITYCATEGORY](security_category)
      this.$refs.modal.openModal()
    },

    modalDeleteSecurityCategory(security_category) {
      this[SecurityCategoryMutations.SET_SELECTED_SECURITYCATEGORY](security_category)
      this.$refs.deleteModal.openModal()
    },

    removeSecurityCategory() {
      this.[SecurityCategoryActions.DELETE_SECURITYCATEGORY]()
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('security_category_deleted'))

          this.$refs.deleteModal.closeModal()

          this.reloadData()
        })
        .catch(() => this.$refs.deleteModal.submitting = false)
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    getModalTitle() {
      return this.selectedSecurityCategory ? this.$t('edit_security_category') : this.$t('new_security_category')
    }
  }
}

</script>