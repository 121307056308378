var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultCrudView',{attrs:{"breadcrumbsItems":_vm.breadcrumbs,"actions":_vm.crudActions,"containerLarge":""},on:{"create-new":_vm.newSecurityCategory}},[_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.security_categories,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemsLength,"footer-props":_vm.$defaultDataTableFooterProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.tenant_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTenantTitle(item.tenant_id))+" ")]}},{key:"item.actions_key",fn:function(ref){
var item = ref.item;
return _vm._l((item.actions_key),function(key,index){return _c('span',{key:key,attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(key)),(index != item.actions_key.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editSecurityCategory(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.modalDeleteSecurityCategory(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])]}}])}),_c('DefaultModal',{ref:"modal",attrs:{"title":_vm.getModalTitle()},on:{"submit":_vm.save},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DynamicFormContent',{attrs:{"fields":_vm.fields}})]},proxy:true}])}),_c('DefaultModal',{ref:"deleteModal",attrs:{"title":_vm.$t('delete_security_category'),"submit-button-text":"delete","deleteModal":""},on:{"submit":_vm.removeSecurityCategory},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('remove_security_category'))+" '"),_c('b',[_vm._v(_vm._s(_vm.selectedSecurityCategory ? _vm.selectedSecurityCategory.mark_category_key : ''))]),_vm._v("' ? ")]},proxy:true}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }